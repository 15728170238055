import { ROLE_STAFF_PANIC } from '~/const/user/roles'
import { defineNuxtRouteMiddleware, navigateTo } from '#app'
import { useAuthStore } from '~/stores/auth'

export default defineNuxtRouteMiddleware((to) => {
    const auth = useAuthStore()

    if (!auth.loggedIn || auth.user.role === ROLE_STAFF_PANIC) {
        return navigateTo('/')
    }
})
